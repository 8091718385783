<template>
	<div class="personal">
		<div class="personal-T">
			<div class="personal-TL">
				<Breadcrumb class="bre"></Breadcrumb>
			</div>
			<div class="personal-TR">
				<div class="personal-searchsMai">
					<el-button type="danger" icon="el-icon-edit-outline" @click="getinfo()">编辑信息</el-button>
				</div>
			</div>
		</div>
		<div class="personal-B">
			<div class="personal-B-mai">
				<div class="personal-B-mai-L">
					<img src="../../assets/img/logo5.png" alt="" srcset="">
				</div>
				<div class="personal-B-mai-C">
					<div class="personal-B-mai-C1">

					</div>
					<div class="personal-B-mai-C2">

					</div>
					<div class="personal-B-mai-C3">

					</div>
				</div>
				<div class="personal-B-mai-R">
					<div class="personal-B-mai-R1">
						<p style="font-size: 26px;">{{forms.T_name}}</p>
						<p style="font-size: 12px;margin-bottom: 2px;">（在线）</p>
					</div>
					<div class="personal-B-mai-R2">
						<p class="personal-B-mai-R2txt">
							UUID：{{forms.T_uuid}}
						</p>
						<p class="personal-B-mai-R2txt">
							电话：{{forms.T_phone | Setphone(forms.T_phone)}}
						</p>
						<p class="personal-B-mai-R2txt">
							邮箱：{{forms.T_mail | Setmail(forms.T_mail)}}
						</p>
						<p class="personal-B-mai-R2txt">
							账号：{{forms.T_user}}
						</p>
						<p class="personal-B-mai-R2txt">
							UUID：{{forms.T_uuid}}
						</p>
						<div style="display: flex;align-items: flex-start;margin-top: 10px;">
							<div class="personal-B-mai-R2txt">微信：</div>
							<div class="tabs">
								<el-tag v-for="tag,index in ruleForm.T_wx" :key="index" closable @close='closes(tag)'
									type="success" style="margin-right: 10px;margin-bottom: 10px;">
									{{tag.vxName}}
								</el-tag>
								<el-button icon="el-icon-plus" size="medium" style="margin-bottom: 10px;"
									@click="centerDialogVisible=true">添加绑定</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="编辑个人信息" :visible.sync="dialogVisible" :close-on-click-modal="false" :append-to-body="true">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="名称" prop="T_name">
					<el-input v-model="ruleForm.T_name"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="T_pass">
					<el-input v-model="ruleForm.T_pass" placeholder="不输入密码则不修改密码" show-password></el-input>
				</el-form-item>
				<el-form-item label="电话">
					<el-input v-model="ruleForm.T_phone" maxlength="11"></el-input>
				</el-form-item>
				<el-form-item label="邮箱">
					<el-input v-model="ruleForm.T_mail"></el-input>
				</el-form-item>
				<!-- <el-form-item label="微信">
					<el-badge v-if="ruleForm.T_wx!=''" class="item">
						<el-button size="small">已绑定</el-button>
					</el-badge>
					<div style="border:1px solid #DCDFE6;border-radius: 4px; width:120px;height:120px;overflow: hidden;"
						v-else>
						<el-image style="width: 100%; height: 100%" :src="Qr" :preview-src-list="srcList">
						</el-image>
					</div>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
					<el-button @click="dialogVisible=false">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" :append-to-body="true">
			<el-form label-position="top" :model="Qrfrom" :rules="rules1" ref="ruleForm1">
				<el-form-item label="请输入名称" prop="T_name">
					<el-input v-model="Qrfrom.T_name"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitForms('ruleForm1')">确 定</el-button>
			</span>
			<el-dialog :visible.sync="centerDialogVisibleEr" width="40%" :append-to-body="true"
				:close-on-click-modal='false' :close-on-press-escape='false'>
				<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
					<h2>微信扫一扫</h2>
					<el-image style="width: 250px; height: 250px" :src="qrUrl" :preview-src-list="srcList">
					</el-image>
					<el-button type="primary" style="margin: 10px;" @click="getQr">我已关注微信公众号</el-button>
					<div style="font-size: 12px;padding-top: 10px;transform: scale(.8);display: flex;">
						<div>注：</div>
						<div>
							<p style="margin-bottom: 10px;">1.只允许【张三】一个人扫码，不能多人共扫一个二维码，多人请一个一个的添加绑定</p>
							<p style="margin-bottom: 10px;">2.使用微信扫码，并关注微信公众号</p>
							<p style="margin-bottom: 10px;">3.已关注微信公众号，点击我已关注完成绑定</p>
						</div>
					</div>
				</div>
			</el-dialog>
		</el-dialog>
	</div>
</template>
<script>
	import {
		UserInfo,
		UserEdit,
		UserWxQRCode
	} from '../../api/systemManagement/personal.js'
	export default {
		data() {
			return {
				qrUrl: '',
				srcList: [],
				Qrfrom: {
					T_name: ''
				},
				rules1: {
					T_name: [{
						required: true,
						message: '请输入微信名称',
						trigger: 'blur'
					}, ],
				},
				forms: {},
				Valye1: '',
				Valye2: '',
				dialogVisible: false,
				centerDialogVisible: false,
				centerDialogVisibleEr: false,
				ruleForm: {
					T_name: '',
					T_pass: '',
					T_phone: '',
					T_mail: '',
					T_wx: [],
				},
				rules: {
					T_name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur',
					}],
					T_pass: [{
						required: true,
						message: '请填写密码（字母+数字，8-18位）',
						// pattern: /^(?:(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])).{8,18}$/,//大写+小写+数字
						pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d].{8,18}$/, //字母+数字
						trigger: 'blur'
					}],
					// T_phone: [{
					// 	required: true,
					// 	message: '请输入正确的手机号',
					// 	pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/,
					// 	trigger: 'blur'
					// }],
					// T_mail: [{
					// 	required: true,
					// 	message: '请输入正确的邮箱格式',
					// 	trigger: 'change',
					// 	pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
					// }],
				},
				Qr: '',
				srcList: []
			}
		},
		filters: {
			Setphone(e) {
				return e === undefined || [...e].length != 11 ? '未设置手机号' : e
			},
			Setmail(e) {
				var mail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
				return !mail.test(e) ? '未设置邮箱' : e
			}
		},
		mounted() {
			this.GetUserInfoApi()
		},
		methods: {
			GeQRApi() {
				return new Promise(resolve => {
					UserWxQRCode(this.Qrfrom).then(res => {
						this.qrUrl = res.data.Data
						this.srcList.push(res.data.Data)
						this.centerDialogVisibleEr = true
						this.centerDialogVisible = false
						resolve(res)
					})
				})
			},
			submitForms(formName) { //添加绑定通过之后调取二维码
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.srcList = []
						//调取二维码
						this.GeQRApi()
					} else {
						////console.log('error submit!!');
						return false;
					}
				});
			},
			closes(e) {
				this.$confirm('此操作将删除已绑定的用户, 是否继续?', '提示', {
					confirmButtonText: '立即删除',
					cancelButtonText: '取消',
					type: 'warning',
				    center: true
				}).then(() => {
					var arr = [...this.ruleForm.T_wx]
					const b = arr.findIndex(item => item.vxName == e.vxName)
					arr.splice(b, 1)
					var obj = ''
					arr.forEach(item=>{
						obj =  obj + item.vxKey+'/'+item.vxName+'|'
					})
					UserEdit({T_wx:obj==''?'-':obj}).then(res => {
						if (res.data.Code === 200 && res.data.Msg === 'ok!') {
							this.GetUserInfoApi()
							this.$message.success('操作成功，已经删除绑定用户')
							this.dialogVisible = false
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			async getQr() {
				UserInfo({}).then(res => {
					if (res.data.Code === 200 && res.data.Msg === "ok!") {
						this.forms = res.data.Data.User
						var arr = res.data.Data.User.T_wx.split('|')
						arr.pop()
						if (arr.length == this.ruleForm.T_wx.length) {
							this.$message.error('绑定失败，请检查是否已关注微信公众号')
						} else {
							this.centerDialogVisibleEr = false
							this.GetUserInfoApi()
						}
					}
				})
			},
			GetUserInfoApi() {
				UserInfo({}).then(res => {
					if (res.data.Code === 200 && res.data.Msg === "ok!") {
						this.forms = res.data.Data.User
						var arr = res.data.Data.User.T_wx.split('|')
						arr.pop()
						var arr1 = []
						arr.forEach(item => {
							var obj = {
								vxKey: '',
								vxName: ''
							}
							obj.vxKey = item.split('/')[0]
							obj.vxName = item.split('/')[1]
							arr1.push(obj)
						})
						this.ruleForm.T_wx = arr1
						////console.log('用户信息', arr1, this.ruleFor)
					}
				})
			},
			async getinfo() { //点击编辑按钮
				this.dialogVisible = true
				this.rules.T_pass[0].required = false
				this.ruleForm.T_name = this.forms.T_name
				this.ruleForm.T_pass = this.forms.T_pass
				this.ruleForm.T_phone = this.forms.T_phone
				this.ruleForm.T_mail = this.forms.T_mail
				this.ruleForm.T_wx = ''
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.GetUserEditApi()
					} else {
						////console.log('error submit!!');
						return false;
					}
				});
			},
			GetUserEditApi() {
				////console.log('密码', this.ruleForm)
				var obj = {
					...this.ruleForm
				}
				if (obj.T_pass) {
					obj.T_pass = this.$md5(obj.T_pass)
				}
				////console.log('密码2', obj)
				UserEdit(obj).then(res => {
					if (res.data.Code === 200 && res.data.Msg === 'ok!') {
						this.GetUserInfoApi()
						this.$message.success('个人信息编辑成功')
						this.dialogVisible = false
					}
				})
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		}
	}
</script>

<style lang="scss">
	.personal {
		user-select: none;
		// height: 100vh;
		display: flex;
		flex-direction: column;

		.personal-T {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(#fff, 1);
			height: 70px;
			padding: 15px 10px;
			box-shadow: 5px 5px 10px -10px #000;

			.personal-TL {
				display: flex;
				align-items: center;
			}

			.personal-searchsMai {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}

		.personal-B {
			flex: 1;
			margin: 10px;
			display: flex;
			align-items: center;
			background: #fff;

			.personal-B-mai {
				display: flex;
				border-radius: 10px;

				.personal-B-mai-L {
					width: 350px;
					height: 350px;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 50%;
						height: 50%;
					}
				}

				.personal-B-mai-C {
					display: flex;
					flex-direction: column;

					.personal-B-mai-C1 {
						flex: .3;
						width: 25px;
						background: #17a654;
					}

					.personal-B-mai-C2 {
						flex: .2;
						width: 25px;
					}

					.personal-B-mai-C3 {
						flex: .5;
						width: 25px;
						background: #17a654;
					}
				}

				.personal-B-mai-R {
					min-width: 400px;
					padding-left: 100px;
					display: flex;
					flex-direction: column;
					justify-content: center;

					.personal-B-mai-R1 {
						color: #17a654;
						display: flex;
						align-items: flex-end;
					}

					.personal-B-mai-R2 {
						margin-top: 10px;

						.personal-B-mai-R2txt {
							min-width: 40px;
							font-size: 12px;
							line-height: 28px;
							color: #929292;
						}

						.tabs {
							width: 100%;
							display: flex;
							align-items: center;
							flex-wrap: wrap;
							// display: grid;
							// grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
							// grid-gap: 10px;
						}
					}
				}

				@media screen and (max-width: 1000px) {
					.personal-B-mai-L {
						display: none;
					}

					.personal-B-mai-C {
						display: none;
					}

					.personal-B-mai-R {
						min-width: 400px;
						padding: 100px;
						display: flex;
						flex-direction: column;
						justify-content: center;

						.personal-B-mai-R1 {
							color: #17a654;
							display: flex;
							align-items: flex-end;
						}

						.personal-B-mai-R2 {
							margin-top: 10px;

							p {
								font-size: 12px;
								line-height: 28px;
								color: #929292;
							}
						}
					}
				}
			}
		}
	}
</style>
