// 系统日志接口
import {
	axios
} from '../../utils/http.js'

const DevApi = {
	UserInfo: '/v3/User/Info',//个人信息
	UserEdit: '/v3/User/Post',//用户日志编辑信息
	UserWxQRCode: '/v3/User/WxQRCode',//获取二维码
}
//个人信息
export function UserWxQRCode(data) {
	return axios({
		url: DevApi.UserWxQRCode,
		method: 'post',
		data:data
	})
}
//个人信息
export function UserInfo(data) {
	return axios({
		url: DevApi.UserInfo,
		method: 'post',
		data:data
	})
}

//用户日志编辑信息
export function UserEdit(data) {
	return axios({
		url: DevApi.UserEdit,
		method: 'post',
		data:data
	})
}
